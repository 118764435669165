import {
  AudioPlayerPlaylistItem,
  ComposeAudioPlayer,
} from 'types/interface/AudioPlayer'
import { nextTick, ref, computed } from 'vue'
import { useAssets } from '@/core'

const { getAssetById } = useAssets()
const showPlayer = ref(false)
const playContinuous = ref(false)
const player = ref(null)
const currentAudio = ref(null)
const isPlaying = ref(false)
const playlist = ref([])
const setCurrentAudio = (item: { audioId: string }): void => {
  currentAudio.value = item
}
const currentAudioURL = computed<string>(
  () => currentAudio.value && getAssetById(currentAudio.value.audioId)?.url,
)
const playAudio = (): void => {
  isPlaying.value = true
  showPlayer.value = true

  if (player.value) {
    player.value.play()
  } else {
    nextTick(() => {
      player.value.play()
    })
  }
}
const pauseAudio = (): void => {
  player.value.pause()
  isPlaying.value = false
}
const findIndexAudio = (item: AudioPlayerPlaylistItem): number =>
  playlist.value.findIndex((listItem) => listItem.sectionId === item.sectionId)
const hasNextAudio = (): boolean => {
  const index = findIndexAudio(currentAudio.value)

  return typeof playlist.value[index + 1] === 'object'
}
const goToNextAudio = (): void => {
  const index = findIndexAudio(currentAudio.value)
  const item = playlist.value[index + 1]

  if (item) {
    currentAudio.value = item
  }
}
const scrollToSection = (): void => {
  const el = document.querySelector(
    `#audio-section-${currentAudio.value.sectionId}`,
  ) as HTMLElement

  if (!el) return

  window.scrollTo({
    left: 0,
    top: el.offsetTop - 80,
    behavior: 'smooth',
  })
}

const clearAudioPlayer = (): void => {
  showPlayer.value = false
  currentAudio.value = null
  isPlaying.value = false
  playlist.value = []
}

export default (): ComposeAudioPlayer => ({
  showPlayer,
  playContinuous,
  currentAudio,
  isPlaying,
  playlist,
  player,
  currentAudioURL,
  playAudio,
  pauseAudio,
  hasNextAudio,
  goToNextAudio,
  scrollToSection,
  setCurrentAudio,
  clearAudioPlayer,
})
