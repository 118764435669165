
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  nextTick,
} from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useRoute, useRouter } from 'vue-router'
import { useStructures, useContents, useAssets } from '@/core'
import useCourseProgress from '@/composables/useCourseProgress'
import {
  getLessonQuestions,
  saveLessonQuestions,
} from '@/services/lessonQuestions'

import Topbar from '@/components/Topbar.vue'
import AudioPlayerFixed from '@/components/AudioPlayerFixed.vue'
import LessonRenderer from '@/components/LessonRenderer.vue'
import LessonNavigation from '@/components/LessonNavigation.vue'
import useAudioPlayer from '@/composables/useAudioPlayer'
import {
  LessonQuestionsResults,
  LessonQuestionErudio,
  LessonQuestions,
} from 'types/interface/CourseProgress'

export default defineComponent({
  components: {
    AudioPlayerFixed,
    Topbar,
    LessonRenderer,
    LessonNavigation,
  },

  setup() {
    const { tenant } = useTenant()
    const { fetchAsset } = useAssets()
    const route = useRoute()
    const router = useRouter()
    const { fetchObject } = useContents()
    const { lessonQuestionsID, setLessonQuestions } = useCourseProgress()
    const {
      fetchStructuresNode,
      getStructuresBySlugPath,
      fetchStructuresChildren,
    } = useStructures()

    const slugPath = computed(() =>
      Array.isArray(route.params.slugPath)
        ? route.params.slugPath.join('/')
        : route.params.slugPath,
    )

    const parentSlugPath = computed(() =>
      slugPath.value.split('/').slice(0, -1).join('/'),
    )
    const parent = computed(() =>
      getStructuresBySlugPath(parentSlugPath.value, true),
    )
    const currentLessonIndex = computed(() =>
      parent.value?.childrens?.findIndex(
        (ch: { id: string }) => ch.id === lesson.value.id,
      ),
    )
    const nextLesson = computed(() => {
      const nextLessonItem =
        parent.value?.childrens[currentLessonIndex.value + 1]

      return nextLessonItem
    })
    const lesson = computed(() =>
      getStructuresBySlugPath(slugPath.value, true, 'OBJECT'),
    )
    const lessonItems = computed(
      () =>
        lesson.value.childrens &&
        lesson.value?.childrens[0]?.contentData?.content.items,
    )
    const {
      showPlayer,
      playlist,
      playContinuous,
      playAudio,
      hasNextAudio,
      goToNextAudio,
      scrollToSection,
      clearAudioPlayer,
      setCurrentAudio,
    } = useAudioPlayer()
    const hasNavigation = computed(() => {
      return parent.value?.childrens?.length > 1
    })
    const buildPlaylist = async () => {
      const audioSections = lessonItems.value.filter(
        (item: { _type: string }) => item._type === 'audioSection',
      )
      Promise.all(
        audioSections.map(async (section: { audioId: string }) => {
          await fetchAsset(section.audioId)
        }),
      )
      playlist.value = audioSections
    }
    const onEnded = () => {
      if (hasNextAudio()) {
        goToNextAudio()
        scrollToSection()
      } else {
        if (nextLesson.value) {
          playContinuous.value = true
          router.push({
            name: 'Lesson',
            params: { slugPath: nextLesson.value.slugPath.split('/') },
          })
        } else {
          router.push({
            name: 'ChapterList',
          })
        }
      }
    }

    const teacherEmail = computed(() => tenant.value.teacherEmail)

    useHead({
      title: computed(() => `${lesson.value?.name} - ${tenant.value.name}`),
    })

    onBeforeMount(() => {
      clearAudioPlayer()
    })
    onMounted(async () => {
      await fetchStructuresNode(slugPath.value)
      await fetchStructuresChildren(slugPath.value)
      // fetch parent
      if (!parent.value.childrens) {
        await fetchStructuresNode(parentSlugPath.value)
        await fetchStructuresChildren(parentSlugPath.value)
      }
      await Promise.all(
        lesson.value.childrens.map(async (ch: { contentId: string }) => {
          if (ch.contentId) {
            await fetchObject(ch.contentId)
          }
        }),
      )
      buildPlaylist()
      if (playContinuous.value) {
        if (playlist.value.length > 0) {
          setCurrentAudio(playlist.value[0])
          scrollToSection()
          playAudio()
        }

        playContinuous.value = false
      }
      let lq: LessonQuestions = (
        await getLessonQuestions(lessonQuestionsID.value)
      )?.lessonQuestions

      if (!lq) {
        const questionItems = lessonItems.value.filter(
          (item: { _type: string }) => item._type === 'questions',
        )
        const generateResults = (): LessonQuestionsResults[] => {
          const arr: Array<LessonQuestionsResults> = []

          questionItems.forEach((qItems: { items: LessonQuestionErudio[] }) => {
            qItems.items.forEach(
              ({ questionsPath, questionId }: LessonQuestionErudio) => {
                arr.push({
                  questionsPath,
                  questionId,
                })
              },
            )
          })

          return arr
        }
        const lessonQuestionsObject = {
          id: lesson.value.id,
          slugPath: lesson.value.slugPath,
          results: generateResults(),
        }
        lq = lessonQuestionsObject
        await saveLessonQuestions(lq, lessonQuestionsID.value)
      }
      setLessonQuestions(lq)

      const sectionId = router.currentRoute.value.hash.replace('#', '')
      if (sectionId) {
        const audioSection = window.document.getElementById(sectionId)
        await nextTick(() => audioSection.scrollIntoView({ block: 'center' }))
      }
    })

    return {
      showPlayer,
      lessonItems,
      hasNavigation,
      nextLesson,
      parentSlugPath,
      onEnded,
      teacherEmail,
    }
  },
})
