<template>
  <div class="c-lesson-navigation">
    <div v-if="nextLesson" class="c-lesson-navigation__next">
      <TheButton
        :to="{
          name: 'Lesson',
          params: { slugPath: nextLesson.slugPath.split('/') },
        }"
        icon="arrow-right"
        icon-pos="right"
      >
        {{ $t('LESSON_NAVIGATION_NEXT') }} “{{ nextLesson.name }}”
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  components: {
    TheButton,
  },

  props: {
    nextLesson: Object,
  },
})
</script>

<style lang="scss">
.c-lesson-navigation {
  display: flex;
  margin-top: rem(40px);

  &__next {
    margin-left: auto;

    .c-button {
      color: $color-8;
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        font-weight: 700;
      }

      .vb-icon-arrow-right {
        &::before {
          display: inline-block;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
