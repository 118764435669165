<template>
  <div :id="`audio-section-${data.sectionId}`" class="c-audio-section">
    <TheHeading :level="headingLevel" class="c-audio-section__heading">{{
      data.title
    }}</TheHeading>
    <div class="c-audio-section__actions">
      <TheButton
        class="c-audio-section__toggle-play-btn"
        type="audio-section"
        :icon="togglePlayButtonIcon"
        icon-set="icon-apf"
        @click="togglePlay"
        >{{ togglePlayButtonText }}</TheButton
      >
      <TheButton
        :class="[
          'c-audio-section__bookmark-btn',
          { 'c-audio-section__bookmark-btn--active': isActive },
        ]"
        type="audio-section"
        @click="addBookmark"
      >
        <i
          :class="[
            'vb-icon',
            'c-audio-section__bookmark-icon',
            {
              'vb-icon-bookmark-outline': !isActive,
              'vb-icon-bookmark': isActive,
            },
          ]"
        ></i>
        <span class="c-audio-section__bookmark-text">{{
          toggleBookmarkText
        }}</span>
      </TheButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton.vue'
import TheHeading from '@/components/TheHeading.vue'
import useAudioPlayer from '@/composables/useAudioPlayer'
import { useStructures, useAuth, useTenant } from '@/core'

import {
  saveBookmarkContent,
  removeBookmark,
  isBookmarkActive,
} from '@/services/bookmarkContent'
import useCourseProgress from '@/composables/useCourseProgress'

export default defineComponent({
  name: 'AudioSection',

  components: {
    TheButton,
    TheHeading,
  },

  props: {
    data: Object,
    index: Number,
  },

  setup(props) {
    const isActive = ref(false)
    const { user } = useAuth()
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { playAudio, pauseAudio, isPlaying, currentAudio, setCurrentAudio } =
      useAudioPlayer()
    const headingLevel = computed(() => (props.index === 0 ? 'h1' : 'h2'))
    const { slugPath } = useCourseProgress()
    const { getStructuresBySlugPath } = useStructures()

    const bookmarkID = computed(
      () =>
        `${tenant.value.slug}-${user.value._id}-bookmark-${slugPath.value}-${props.data.sectionId}`,
    )
    const lesson = computed(() =>
      getStructuresBySlugPath(slugPath.value, true, 'OBJECT'),
    )

    const toggleBookmarkText = computed(() =>
      isActive.value ? t('BOOKMARK_CONTENT_SAVED') : t('BOOKMARK_CONTENT_SAVE'),
    )

    const togglePlayButtonIcon = computed(() =>
      isPlaying.value && isCurrentAudio.value ? 'player-pause' : 'player-play',
    )

    const togglePlayButtonText = computed(() =>
      isPlaying.value && isCurrentAudio.value
        ? t('LESSON_AUDIO_PAUSE')
        : t('LESSON_AUDIO_PLAY'),
    )
    const isCurrentAudio = computed(
      () => props.data.sectionId === currentAudio.value?.sectionId,
    )
    const togglePlay = (): void => {
      if (isPlaying.value && isCurrentAudio.value) {
        pauseAudio()
      } else {
        setCurrentAudio(props.data)
        playAudio()
      }
    }

    const addBookmark = async () => {
      if (!isActive.value) {
        await saveBookmarkContent(
          bookmarkID.value,
          props.data.title,
          slugPath.value,
          lesson.value.id,
          props.data.sectionId,
        )
        isActive.value = true
      } else if (isActive.value) {
        await removeBookmark(bookmarkID.value)
        isActive.value = false
      }
    }
    onMounted(async () => {
      await isBookmarkActive(bookmarkID.value).then((res: boolean) => {
        if (res) isActive.value = true
      })
    })

    return {
      togglePlayButtonIcon,
      togglePlayButtonText,
      headingLevel,
      togglePlay,
      addBookmark,
      toggleBookmarkText,
      isActive,
      isBookmarkActive,
    }
  },
})
</script>

<style lang="scss">
.c-audio-section {
  margin-top: rem(32px);
  &__actions {
    display: flex;
  }
}

.c-audio-section__heading {
  margin-bottom: rem(8px);
}
.c-audio-section__toggle-play-btn {
  padding-right: rem(35px);
  padding-left: rem(10px);
}
.c-audio-section__bookmark {
  &-btn {
    position: relative;
    margin-left: rem(20px);
    width: rem(46px);
    transition: width 0.4s;
    overflow: hidden;

    &::before {
      position: absolute;
      top: rem(12px);
      left: 0;
      bottom: rem(12px);
      width: rem(40px);
      background-color: $bookmark-bg;
      content: '';
    }
  }

  &-text {
    transform: translateX(-8px);
    transition: transform 0.4s;
    z-index: -1;
  }
}
.c-button--audio-section > i.c-audio-section__bookmark-icon {
  position: absolute;
  top: 50%;
  left: rem(14px);
  transform: translateY(-50%);
  font-size: rem(18px);
}

.c-audio-section__bookmark-btn:hover,
.c-audio-section__bookmark-btn--active {
  width: rem(110px);
}

.c-audio-section__bookmark-btn:hover > .c-audio-section__bookmark-text,
.c-audio-section__bookmark-btn--active .c-audio-section__bookmark-text {
  transform: translateX(12px);
}
</style>
