<template>
  <div class="c-lesson-image" :style="{ maxWidth: data.maxWidth }">
    <a
      class="c-lesson-image__link"
      href="#"
      :aria-label="$t('LESSON_IMAGE_ZOOM')"
      role="button"
      @click.prevent="onMediaModalOpen"
    >
      <Image :image-id="data.imageId" :alt-text="data.altText"
    /></a>
    <div v-if="data.caption" class="c-lesson-image__caption">
      {{ data.caption }}
    </div>
    <div v-if="data.copyright" class="c-lesson-image__copyright">
      {{ data.copyright }}
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'
import Image from '@/components/Image.vue'
import { TestImage } from 'types/interface/TestImage'

export default defineComponent({
  components: {
    Image,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { setModal } = useModal()

    const image: TestImage = {
      imageId: props.data.imageId,
      altText: props.data.altText,
      type: 'image',
    }
    const onMediaModalOpen = () =>
      setModal('ModalMedia', {
        content: '',
        media: { image },
        type: 'media',
      })

    return {
      onMediaModalOpen,
    }
  },
})
</script>

<style lang="scss">
.c-lesson-image {
  position: relative;
  margin: rem(16px) 0;
  cursor: pointer;

  @include bp-down($medium) {
    max-width: 100% !important;
  }

  &__link {
    display: flex;
  }
}
</style>
