
import { defineComponent } from 'vue'
import TheButton from '@/components/TheButton.vue'

export default defineComponent({
  components: {
    TheButton,
  },

  props: {
    nextLesson: Object,
  },
})
