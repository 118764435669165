
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import TheButton from '@/components/TheButton.vue'
import TheHeading from '@/components/TheHeading.vue'
import useAudioPlayer from '@/composables/useAudioPlayer'
import { useStructures, useAuth, useTenant } from '@/core'

import {
  saveBookmarkContent,
  removeBookmark,
  isBookmarkActive,
} from '@/services/bookmarkContent'
import useCourseProgress from '@/composables/useCourseProgress'

export default defineComponent({
  name: 'AudioSection',

  components: {
    TheButton,
    TheHeading,
  },

  props: {
    data: Object,
    index: Number,
  },

  setup(props) {
    const isActive = ref(false)
    const { user } = useAuth()
    const { tenant } = useTenant()
    const { t } = useI18n()
    const { playAudio, pauseAudio, isPlaying, currentAudio, setCurrentAudio } =
      useAudioPlayer()
    const headingLevel = computed(() => (props.index === 0 ? 'h1' : 'h2'))
    const { slugPath } = useCourseProgress()
    const { getStructuresBySlugPath } = useStructures()

    const bookmarkID = computed(
      () =>
        `${tenant.value.slug}-${user.value._id}-bookmark-${slugPath.value}-${props.data.sectionId}`,
    )
    const lesson = computed(() =>
      getStructuresBySlugPath(slugPath.value, true, 'OBJECT'),
    )

    const toggleBookmarkText = computed(() =>
      isActive.value ? t('BOOKMARK_CONTENT_SAVED') : t('BOOKMARK_CONTENT_SAVE'),
    )

    const togglePlayButtonIcon = computed(() =>
      isPlaying.value && isCurrentAudio.value ? 'player-pause' : 'player-play',
    )

    const togglePlayButtonText = computed(() =>
      isPlaying.value && isCurrentAudio.value
        ? t('LESSON_AUDIO_PAUSE')
        : t('LESSON_AUDIO_PLAY'),
    )
    const isCurrentAudio = computed(
      () => props.data.sectionId === currentAudio.value?.sectionId,
    )
    const togglePlay = (): void => {
      if (isPlaying.value && isCurrentAudio.value) {
        pauseAudio()
      } else {
        setCurrentAudio(props.data)
        playAudio()
      }
    }

    const addBookmark = async () => {
      if (!isActive.value) {
        await saveBookmarkContent(
          bookmarkID.value,
          props.data.title,
          slugPath.value,
          lesson.value.id,
          props.data.sectionId,
        )
        isActive.value = true
      } else if (isActive.value) {
        await removeBookmark(bookmarkID.value)
        isActive.value = false
      }
    }
    onMounted(async () => {
      await isBookmarkActive(bookmarkID.value).then((res: boolean) => {
        if (res) isActive.value = true
      })
    })

    return {
      togglePlayButtonIcon,
      togglePlayButtonText,
      headingLevel,
      togglePlay,
      addBookmark,
      toggleBookmarkText,
      isActive,
      isBookmarkActive,
    }
  },
})
