<template>
  <div v-for="question of questions" :key="question.id" class="c-lesson-test">
    <div class="c-lesson-test__heading">Spørsmål</div>
    <div class="c-lesson-test__content">
      <div class="c-lesson-test__question" v-md-html="question.text"></div>
      <TestMedia
        v-if="questionMedia(question)"
        :media="questionMedia(question)"
      />
      <div class="c-lesson-test__answers">
        <TestAnswer
          v-for="answer in question.answers"
          :key="answer.text"
          :answer="answer"
          @click="answerSelected(question, answer)"
          :selected="isSelected(answer)"
          :showResult="true"
          :disabled="questionAnswered(question)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onBeforeMount, ref } from 'vue'
import { useStructures, useContents } from '@/core'
import {
  Answer,
  Question,
  QuestionAssets,
} from 'types/interface/ComposeQuestion'
import TestAnswer from '@/components/TestAnswer.vue'
import TestMedia from '@/components/TestMedia.vue'
import useModal from '@/composables/useModal'
import { useI18n } from 'vue-i18n'
import useCourseProgress from '@/composables/useCourseProgress'
import {
  getLessonQuestions,
  saveLessonQuestions,
} from '@/services/lessonQuestions'
import {
  LessonQuestionErudio,
  LessonQuestionsDB,
  LessonQuestionsResults,
} from 'types/interface/CourseProgress'
import {
  getPreselectedQuestions,
  setPreselectedQuestions,
} from '@/services/results'

export default defineComponent({
  components: {
    TestAnswer,
    TestMedia,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const questions = ref<Question[]>([])
    const selectedAnswers = ref<Answer[]>([])
    const answeredQuestions = ref<Question[]>([])
    const { lessonQuestionsID, setLessonQuestions } = useCourseProgress()

    const { fetchStructuresNode, getStructuresBySlugPath } = useStructures()
    const { fetchObject, getObjectById } = useContents()
    const { closeModal, setModal } = useModal()
    const { t } = useI18n()

    onBeforeMount(async () => {
      const sets: { [key: string]: Question[] } = {}
      await Promise.all(
        props.data.items.map(async (setObj: LessonQuestionErudio) => {
          await fetchStructuresNode(setObj.questionsPath)
          const setData = getStructuresBySlugPath(
            setObj.questionsPath,
            true,
            'OBJECT',
          )
          if (!sets[setObj.questionsPath]) {
            await fetchObject(setData.contentId)
            sets[setObj.questionsPath] = getObjectById(
              setData.contentId,
            ).content.questions
            sets[setObj.questionsPath].forEach((q) => {
              if (q.timestamp) {
                q.id = q.timestamp
              }
            })
          }
        }),
      )

      props.data.items.forEach((item: LessonQuestionErudio) => {
        const question = sets[item.questionsPath].find(
          (q) => q.id === item.questionId,
        )
        if (question) {
          questions.value.push(question)
        } else {
          console.info(
            `QUESTION MISSING: path:${item.questionsPath}, id: ${item.questionId}`,
          )
        }
      })
    })

    const answerSelected = async (question: Question, answer: Answer) => {
      if (questionAnswered(question)) return
      selectedAnswers.value.push(answer)
      answeredQuestions.value.push(question)
      if (!answer.correct) {
        const preselected = await getPreselectedQuestions()
        const preselectedQuestions = preselected ? preselected.questionIds : []
        const preselectedCorrectIds = preselected?.correctIds
          ? preselected.correctIds
          : []
        const preselectedQuestionsRev = preselected ? preselected._rev : null
        if (preselectedQuestions.indexOf(question.id) === -1) {
          preselectedQuestions.push(question.id)
          await setPreselectedQuestions(
            preselectedQuestions,
            preselectedCorrectIds,
            preselectedQuestionsRev,
          )
        }
        selectedAnswers.value.push(...question.answers.filter((a) => a.correct))
      }
      const savedLessonQuestions: LessonQuestionsDB = await getLessonQuestions(
        lessonQuestionsID.value,
      )
      savedLessonQuestions.lessonQuestions.results.map(
        (result: LessonQuestionsResults) => {
          if (result.questionId === question.id) {
            result.answer = answer
          }

          return result
        },
      )
      await saveLessonQuestions(
        savedLessonQuestions.lessonQuestions,
        lessonQuestionsID.value,
      )
      setLessonQuestions(savedLessonQuestions.lessonQuestions)

      setTimeout(() => {
        setModal('ModalResults', {
          title: 'Svar',
          content: question,
          type: 'feedbackOnly',
          success: answer.correct,
          actions: {
            confirm: {
              title: answer.correct
                ? t('RESULTS_MODAL_BTN_TEXT_SUCCESS')
                : t('RESULTS_MODAL_BTN_TEXT_FAIL'),
              on() {
                closeModal()
              },
            },
          },
        })
      }, 500)
    }

    const isSelected = (answer: Answer) => {
      return selectedAnswers.value.includes(answer)
    }

    const questionAnswered = (question: Question) => {
      return answeredQuestions.value.includes(question)
    }

    const questionMedia = (question: Question) => {
      return question.assets.find(
        (asset: QuestionAssets) =>
          asset.type === 'video' || asset.type === 'image',
      )
    }
    return {
      questions,
      selectedAnswers,
      answerSelected,
      isSelected,
      questionAnswered,
      questionMedia,
    }
  },
})
</script>
<style lang="scss" scoped>
.c-lesson-test {
  margin: rem(16px) 0;
  &__heading {
    padding: rem(8px) rem(16px);
    font-size: 2rem;
    color: $lesson__question--heading-color;
    background: linear-gradient(
      $lesson__question--heading-bg1,
      $lesson__question--heading-bg2
    );
    border: 2px solid $lesson__question--border;
    border-radius: rem(16px) rem(16px) 0 0;
    border-bottom: none;
  }
  &__content {
    border: 2px solid $lesson__question--border;
    border-radius: 0 0 rem(16px) rem(16px);
  }
  &__question {
    padding: 0 rem(16px);
  }
  &__answers {
    padding: rem(8px);
  }
}
</style>
