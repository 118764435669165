<template>
  <div class="c-content-renderer">
    <div
      v-if="contentItems && contentItems.length"
      class="c-content-renderer__wrapper"
    >
      <div
        class="c-content-renderer__element"
        :class="[`c-content-renderer__element--${item._type}`]"
        v-for="(item, i) in contentItems"
        :key="item._type + i"
      >
        <component
          v-if="item"
          :is="mapComponent(item._type)"
          :key="item._type + i"
          :index="i"
          :data="item"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ElementAudioSection from '@/components/LessonElements/audioSection.vue'
import ElementText from '@/components/LessonElements/text.vue'
import ElementImage from '@/components/LessonElements/image.vue'
import ElementQuestions from '@/components/LessonElements/questions.vue'
import ElementVideo from '@/components/LessonElements/video.vue'

export default defineComponent({
  name: 'ContentRenderer',

  props: {
    contentItems: Array,
  },

  components: {
    ElementAudioSection,
    ElementQuestions,
    ElementText,
    ElementImage,
    ElementVideo,
  },

  setup() {
    const mapComponent = (type: string): string => {
      const types = {
        video: 'ElementVideo',
        image: 'ElementImage',
        text: 'ElementText',
        questions: 'ElementQuestions',
        audioSection: 'ElementAudioSection',
      }

      return types[type as keyof typeof types]
    }

    return {
      mapComponent,
    }
  },
})
</script>
