<template>
  <div
    v-if="hasPlaceholder"
    class="c-audio-player-fixed__placeholder"
    :style="{ height: `${playerHeight}px` }"
  />
  <div
    :class="[
      'c-audio-player-fixed',
      { 'c-audio-player-fixed--show': showPlayer && currentAudio },
    ]"
    role="region"
    :aria-label="ariaLabelMain"
    ref="audioContainer"
  >
    <div v-if="currentAudio" class="c-audio-player-fixed__wrapper">
      <audio
        ref="player"
        crossorigin=""
        class="c-audio-player-fixed__native"
        @play="isPlaying = true"
        @pause="isPlaying = false"
        @timeupdate="updateCurrentTime"
        @durationchange="updateDurationTime"
        @ended="onEnded"
      >
        <source :src="currentAudioURL" />
      </audio>
      <div class="c-audio-player-fixed__header">
        <div class="c-audio-player-fixed__progress">
          <span class="c-audio-player-fixed__title">{{
            currentAudio.title
          }}</span>
          <div
            class="c-audio-player-fixed__slider"
            @keydown="onSliderKeydown"
            @click="onSliderClick"
            tabindex="0"
            :aria-valuetext="ariaLabelSlider"
            aria-valuemax="100"
            aria-valuemin="0"
            :aria-valuenow="Math.round(sliderPercentage)"
            role="slider"
            ref="slider"
          >
            <div class="c-audio-player-fixed__length">
              <svg
                class="c-audio-player-fixed__bar"
                viewBox="0 0 100% 6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0" :width="sliderPercentage + '%'" height="3" rx="3" />
              </svg>
            </div>
          </div>
        </div>
        <button class="c-audio-player-fixed__btn" @click="onClose">
          <i class="c-audio-player-fixed__close icon-apf-player-close"></i>
        </button>
      </div>
      <div class="c-audio-player-fixed__controls">
        <button class="c-audio-player-fixed__btn" @click="toggleVolume">
          <i
            :class="[
              'c-audio-player-fixed__volume',
              `icon-apf-player-${isMuted ? 'no-volume' : 'volume'}`,
            ]"
          ></i>
        </button>
        <button
          :class="[
            'c-audio-player-fixed__btn',
            `c-audio-player-fixed__btn--${isPlaying ? 'pause' : 'play'}`,
          ]"
          @click="togglePlay"
        >
          <i
            :class="[
              'c-audio-player-fixed__play',
              `icon-apf-player-${isPlaying ? 'pause' : 'play'}`,
            ]"
          ></i>
        </button>
        <button
          class="c-audio-player-fixed__btn"
          :aria-label="ariaLabelPlaylist"
          @click="togglePlaylist"
        >
          <i class="c-audio-player-fixed__toc icon-apf-player-toc"></i>
        </button>
      </div>
      <div class="c-audio-player-fixed__playlist">
        <AudioPlayerPlaylist v-if="showPlaylist" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, ref, computed, watch } from 'vue'

import AudioPlayerPlaylist from '@/components/AudioPlayerPlaylist.vue'
import useAudioPlayer from '@/composables/useAudioPlayer'

export default defineComponent({
  components: {
    AudioPlayerPlaylist,
  },

  props: {
    ariaLabelMain: {
      type: String,
      default: 'Audio Player',
    },
    ariaLabelPlay: {
      type: String,
      default: 'Play',
    },
    ariaLabelPause: {
      type: String,
      default: 'Audio Player',
    },
    ariaLabelSoundOn: {
      type: String,
      default: 'Turn on the sound',
    },
    ariaLabelSoundOff: {
      type: String,
      default: 'Turn off the sound',
    },
    ariaLabelPlaylist: {
      type: String,
      default: 'Playlist',
    },
    ariaLabelSlider: {
      type: String,
      default: 'Seek audio bar',
    },
    hasPlaceholder: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      player,
      showPlayer,
      currentAudio,
      playAudio,
      pauseAudio,
      currentAudioURL,
    } = useAudioPlayer()
    const audioContainer = ref(null)
    const slider = ref(null)
    const sliderPercentage = computed(() =>
      ((currentTime.value / duration.value) * 100).toFixed(2),
    )
    const isPlaying = ref(false)
    const isMuted = ref(false)
    const currentTime = ref(0)
    const duration = ref(0)
    const showPlaylist = ref(false)
    const togglePlaylist = () => {
      showPlaylist.value = !showPlaylist.value
    }
    const toggleVolume = () => {
      if (player.value.muted) {
        player.value.muted = false
        isMuted.value = false
      } else {
        player.value.muted = true
        isMuted.value = true
      }
    }
    const togglePlay = () => {
      if (!isPlaying.value) {
        playAudio()
      } else {
        pauseAudio()
      }
    }
    const onClose = () => {
      showPlayer.value = false
      pauseAudio()
    }
    const updateCurrentTime = (ev) => {
      currentTime.value = ev.target.currentTime
      if (!slider.value) return
      const pos =
        (ev.pageX -
          (slider.value.getBoundingClientRect().x ||
            slider.value.getBoundingClientRect().left)) /
        slider.value.getClientRects()[0].width

      sliderPercentage.value = pos * 100
    }
    const updateDurationTime = (ev) => {
      duration.value = ev.target.duration
    }
    const updatePlayerCurrentTime = (time) => {
      player.value.currentTime = time
    }
    const onSliderClick = (ev) => {
      if (!slider.value) return
      const pos =
        (ev.pageX -
          (slider.value.getBoundingClientRect().x ||
            slider.value.getBoundingClientRect().left)) /
        slider.value.getClientRects()[0].width

      updatePlayerCurrentTime(duration.value * pos)
    }
    const onSliderKeydown = (ev) => {
      const isLeft = 37
      const isRight = 39
      const isTop = 38
      const isBottom = 40
      const isHome = 36
      const isEnd = 35
      const keyList = [isLeft, isRight, isTop, isBottom, isHome, isEnd]

      if (keyList.indexOf(ev.keyCode) >= 0) {
        let ct
        switch (ev.keyCode) {
          case isLeft:
            ct = currentTime.value - 1
            break
          case isRight:
            ct = currentTime.value + 1
            break
          case isTop:
            ct = currentTime.value + 10
            break
          case isBottom:
            ct = currentTime.value - 10
            break
          case isHome:
            ct = 0
            break
          case isEnd:
            ct = duration.value - 0.1
            break
          default:
            break
        }

        if (ct > duration.value) {
          ct = duration.value
        } else if (ct < 0) {
          ct = 0
        }

        currentTime.value = ct
        updatePlayerCurrentTime(ct)
      }
    }
    const onEnded = () => {
      emit('on-ended')
    }
    const playerHeight = ref(0)
    watch(
      () => currentAudioURL.value,
      () => {
        if (!currentAudioURL.value) return
        if (!player.value) {
          nextTick(() => {
            player.value.load()
            playAudio()
          })
          return
        }
        player.value.load()
        playAudio()
      },
    )
    watch([showPlayer, showPlaylist], (val1) => {
      if (val1[0]) {
        nextTick(() => {
          playerHeight.value = audioContainer.value.clientHeight
        })
      } else {
        playerHeight.value = 0
      }
    })

    return {
      audioContainer,
      player,
      slider,
      showPlayer,
      showPlaylist,
      currentAudio,
      currentAudioURL,
      isPlaying,
      isMuted,
      sliderPercentage,
      onClose,
      togglePlay,
      toggleVolume,
      togglePlaylist,
      updateCurrentTime,
      updateDurationTime,
      updatePlayerCurrentTime,
      onSliderClick,
      onSliderKeydown,
      onEnded,
      playerHeight,
    }
  },
})
</script>

<style lang="scss">
@import '../assets/icons-apf/style.css';

.c-audio-player-fixed {
  background: $audio-player__bg;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  min-height: 100px;
  transition: bottom 0.5s ease-in-out;
}

.c-audio-player-fixed__btn {
  @include btnReset();
  @include a11yOutlineAlt();
}

.c-audio-player-fixed__close,
.c-audio-player-fixed__play,
.c-audio-player-fixed__volume,
.c-audio-player-fixed__toc {
  font-size: rem(44px);
  color: $audio-player__icons;
}

.c-audio-player-fixed__wrapper {
  padding: 1rem;
}

.c-audio-player-fixed__header {
  display: flex;
}

.c-audio-player-fixed__title {
  display: block;
  font-size: rem(18px);
  color: $audio-player__text;
  margin-bottom: rem(4px);
}

.c-audio-player-fixed__progress {
  flex: 1;
  margin-right: rem(40px);
}

.c-audio-player-fixed__controls {
  display: flex;
  justify-content: space-between;
  margin-top: rem(8px);
}

.c-audio-player-fixed--show {
  bottom: 0;
}

.c-audio-player-fixed__slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 88%;
  padding: rem(4px) 0;
  overflow: hidden;
  cursor: pointer;

  @include a11yOutlineAlt();
}

.c-audio-player-fixed__length {
  position: relative;
  width: 100%;
  height: rem(3px);
  background: lighten($audio-player__bg, 10%);
  overflow: hidden;
}

.c-audio-player-fixed__bar {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: rem(3px);
  border: 0;
  appearance: none;
  transition: none;
  overflow: hidden;
  fill: $audio-player__progress;
  stroke: none;
}
</style>
