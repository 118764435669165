
import { defineComponent } from 'vue'
import ElementAudioSection from '@/components/LessonElements/audioSection.vue'
import ElementText from '@/components/LessonElements/text.vue'
import ElementImage from '@/components/LessonElements/image.vue'
import ElementQuestions from '@/components/LessonElements/questions.vue'
import ElementVideo from '@/components/LessonElements/video.vue'

export default defineComponent({
  name: 'ContentRenderer',

  props: {
    contentItems: Array,
  },

  components: {
    ElementAudioSection,
    ElementQuestions,
    ElementText,
    ElementImage,
    ElementVideo,
  },

  setup() {
    const mapComponent = (type: string): string => {
      const types = {
        video: 'ElementVideo',
        image: 'ElementImage',
        text: 'ElementText',
        questions: 'ElementQuestions',
        audioSection: 'ElementAudioSection',
      }

      return types[type as keyof typeof types]
    }

    return {
      mapComponent,
    }
  },
})
