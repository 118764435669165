
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'
import Image from '@/components/Image.vue'
import { TestImage } from 'types/interface/TestImage'

export default defineComponent({
  components: {
    Image,
  },

  props: {
    data: Object,
  },

  setup(props) {
    const { setModal } = useModal()

    const image: TestImage = {
      imageId: props.data.imageId,
      altText: props.data.altText,
      type: 'image',
    }
    const onMediaModalOpen = () =>
      setModal('ModalMedia', {
        content: '',
        media: { image },
        type: 'media',
      })

    return {
      onMediaModalOpen,
    }
  },
})
