<template>
  <div class="c-text">
    <div
      v-if="data && data.content"
      class="c-text__content"
      v-md-html="data.content"
      @concept-click="showConcept"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import useModal from '@/composables/useModal'
export default defineComponent({
  name: 'Text',

  props: {
    data: Object,
  },
  setup() {
    const { setModal } = useModal()

    const showConcept = (e) => {
      const { conceptId } = e.detail

      setModal('ModalConcept', {
        content: conceptId,
        type: 'concept',
      })
    }

    return {
      showConcept,
    }
  },
})
</script>
<style lang="scss">
.c-text {
  font-size: rem(18px);
  line-height: 2rem;

  .concept-link {
    @include btnReset();
    @include a11yOutline();
    display: inline;
    border-bottom: 1px solid $concept-link-border;
  }
  .concept-link-alt {
    @include btnReset();
    @include a11yOutlineWithOffset();
    display: block;
    padding: rem(8px) rem(30px);
    margin: rem(20px) 0;
    width: fit-content;
    font-weight: 700;
    border: rem(2px) solid $concept-link-border;
    border-radius: rem(4px);
    transition: background-color $hover-transition-duration;
    &:hover {
      background-color: $concept-link-border--hover;
    }
  }
  a[target='_blank'] {
    position: relative;
    display: inline-block;
    padding-left: rem(22px);
    &::before {
      position: absolute;
      content: url('~@/assets/svg/link_icon.svg');
      top: rem(2px);
      left: 0;
      width: 1rem;
    }
  }
}
</style>
