
import { defineComponent } from 'vue'

import useAudioPlayer from '@/composables/useAudioPlayer'

export default defineComponent({
  setup() {
    const { playlist, playAudio, setCurrentAudio, scrollToSection } =
      useAudioPlayer()
    const onSelect = (item: { sectionId: string }) => {
      setCurrentAudio(item)
      playAudio()
      scrollToSection()
    }

    return {
      playlist,
      onSelect,
    }
  },
})
