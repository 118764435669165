<template>
  <div class="c-audio-player-playlist">
    <ul class="c-audio-player-playlist__list">
      <li
        v-for="item in playlist"
        :key="item.sectionId"
        class="c-audio-player-playlist__item"
      >
        <a
          href="#"
          @click.prevent="onSelect(item)"
          class="c-audio-player-playlist__item-btn"
        >
          <span>{{ item.title }}</span>
          <span class="c-audio-player-playlist__item-icon">
            <i class="icon-apf-player-play" />
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import useAudioPlayer from '@/composables/useAudioPlayer'

export default defineComponent({
  setup() {
    const { playlist, playAudio, setCurrentAudio, scrollToSection } =
      useAudioPlayer()
    const onSelect = (item: { sectionId: string }) => {
      setCurrentAudio(item)
      playAudio()
      scrollToSection()
    }

    return {
      playlist,
      onSelect,
    }
  },
})
</script>

<style lang="scss">
.c-audio-player-playlist {
  max-height: rem(250px);
  overflow: auto;
}

.c-audio-player-playlist__list {
  margin: 0;
  padding: rem(16px) 0 0;
}

.c-audio-player-playlist__item {
  list-style-type: none;
}

.c-audio-player-playlist__item-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: $audio-player-playlist__btn-text;
  background: $audio-player-playlist__btn-bg;
  font-size: rem(16px);
  font-weight: 700;
  padding: rem(12px) rem(16px);
  margin: rem(16px) 0;
  border-radius: rem(8px);

  @include a11yOutlineAlt();
}

.c-audio-player-playlist__item-icon {
  font-size: rem(28px);
}
</style>
